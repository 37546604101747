const choices = [
  {
    name: "Inside the Perimeter",
    values: [
      {
        name: "Ansley Park",
        value: "ansley_park",
      },
      {
        name: "Brookhaven",
        value: "brookhaven",
      },
      {
        name: "Brookwood Hills",
        value: "brookwood_hills",
      },
      {
        name: "Buckhead",
        value: "buckhead",
      },
      {
        name: "Chamblee",
        value: "chamblee",
      },
      {
        name: "Chastain Park",
        value: "chastain_park",
      },
      {
        name: "Collier Hills",
        value: "collier_hills",
      },
      {
        name: "Decatur",
        value: "decatur",
      },
      {
        name: "Druid Hills",
        value: "druid_hills",
      },
      {
        name: "Garden Hills",
        value: "garden_hills",
      },
      {
        name: "Grant Park",
        value: "grant_park",
      },
      {
        name: "Haynes Manor",
        value: "haynes_manor",
      },
      {
        name: "Inman Park",
        value: "inman_park",
      },
      {
        name: "Midtown",
        value: "midtown",
      },
      {
        name: "Morningside / Lenox Park",
        value: "morningside_lenox_park",
      },
      {
        name: "North Buckhead",
        value: "north_buckhead",
      },
      {
        name: "Old Fourth Ward",
        value: "old_fourth_ward",
      },
      {
        name: "Peachtree Hills",
        value: "peachtree_hills",
      },
      {
        name: "Peachtree Park",
        value: "peachtree_park",
      },
      {
        name: "Springlake / Wildwood / Memorial Park",
        value: "springlake_wildwood_memorial_park",
      },
      {
        name: "Tuxedo Park",
        value: "tuxedo_park",
      },
      {
        name: "Vinings",
        value: "vinings",
      },
      {
        name: "Virginia Highland",
        value: "virginia_highland",
      },
    ],
  },
  {
    name: "Outside the Perimeter",
    values: [
      {
        name: "Acworth",
        value: "acworth",
      },
      {
        name: "Alpharetta",
        value: "alpharetta",
      },
      {
        name: "Canton",
        value: "canton",
      },
      {
        name: "Cumming",
        value: "cumming",
      },
      {
        name: "Dallas",
        value: "dallas",
      },
      {
        name: "Duluth",
        value: "duluth",
      },
      {
        name: "Dunwoody",
        value: "dunwoody",
      },
      {
        name: "East Cobb",
        value: "east_cobb",
      },
      {
        name: "Johns Creek",
        value: "johns_creek",
      },
      {
        name: "Kennesaw",
        value: "kennesaw",
      },
      {
        name: "Marietta",
        value: "marietta",
      },
      {
        name: "Milton",
        value: "milton",
      },
      {
        name: "Rosewell",
        value: "rosewell",
      },
      {
        name: "Sandy Springs",
        value: "sandy_springs",
      },
      {
        name: "Smyrna",
        value: "smyrna",
      },
      {
        name: "Woodstock",
        value: "woodstock",
      },
    ],
  },
];

export const getLocationList = () => choices;
