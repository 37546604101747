<template>
  <div class="max-container" id="calculator">
    <div class="main-grid">
      <h2
        class="col-span-full text-h3 lg:text-h2 font-semibold text-center max-w-[758px] mx-auto mb-6 sm:mb-12"
      >
        Build your dream home with huge
        <span class="text-blue-500">financial upside</span>
      </h2>
      <div
        class="col-span-full text-body-2 lg:text-h4 max-w-[890px] text-center mx-auto"
      >
        HomeFounder finds lots in desirable locations, develops them, and
        connects you to reputable home builders. By working with us, you save up
        to 15% off the price of your home and even more in interest.
      </div>
      <h4
        class="col-span-full text-body-1 lg:text-h4 font-semibold mx-auto text-center my-12"
      >
        Select a home price to see how it impacts your future:
      </h4>

      <div class="col-span-2 col-start-3 lg:col-start-5 lg:col-span-4">
        <div
          class="flex flex-nowrap items-center gap-6 sm:gap-12 justify-around text-body-1 sm:text-h4 mb-12"
        >
          <div v-for="item in homePrices" :key="item.id">
            <button
              class="calc-price-button"
              :class="{ active: activeTab === item.id }"
              @click.prevent="setActiveTab(item.id)"
            >
              {{ roundNumberToShorthandCurrency(item.name) }}
            </button>
          </div>
        </div>
      </div>

      <div class="calc-main-grid">
        <div>
          <div
            class="rounded-t-32 bg-yellow-500 text-body-2 sm:text-h4 font-semibold py-5 px-6 sm:py-7 sm:px-8"
          >
            <p>Upfront home savings</p>
            <div class="text-h3 sm:text-h1">
              {{ currencyNoDecimal(currentHomePrice.upfrontHomeSavings) }}
            </div>
          </div>
          <div
            class="flex flex-wrap justify-between gap-2 rounded-b-32 bg-creme-50 py-5 px-6 sm:py-7 sm:px-8"
          >
            <div class="flex flex-col">
              <span class="font-semibold text-body-2 md:text-h4">
                Appraised value
              </span>
              <span class="calc-infoblock-number text-body-2 md:text-h4">{{
                roundNumberToShorthandCurrency(
                  currentHomePrice.appraisedHomeValue
                )
              }}</span>
            </div>
            <div class="flex flex-col">
              <span class="font-semibold text-body-2 md:text-h4 mr-8">
                HF price
              </span>
              <span class="calc-infoblock-number text-body-2 md:text-h4">{{
                roundNumberToShorthandCurrency(
                  currentHomePrice.homeFounderPrice
                )
              }}</span>
            </div>
          </div>
        </div>

        <div>
          <div
            class="rounded-t-32 bg-blue-500 text-yellow-500 text-body-2 sm:text-h4 font-semibold py-5 px-6 sm:py-7 sm:px-8"
          >
            <p>Total interest savings</p>
            <div class="text-h3 sm:text-h1 flex flex-wrap items-baseline">
              {{ currencyNoDecimal(currentHomePrice.totalInterestSaving) }}
              <div class="text-body-3 w-full sm:w-auto sm:text-body-2">
                over 30 years
              </div>
            </div>
          </div>
          <div
            class="flex justify-between gap-2 rounded-b-32 bg-creme-50 py-5 px-6 sm:py-7 sm:px-8"
          >
            <div class="flex flex-col">
              <span class="font-semibold text-body-2 sm:text-h4"
                >Monthly mortgage savings</span
              >
              <span class="calc-infoblock-number text-body-2 sm:text-h4">{{
                currencyNoDecimal(currentHomePrice.monthlyMortageSaving)
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-span-full mt-4 lg:mt-0 what-will-you-do">
        <div class="bg-creme-50 rounded-32 py-5 px-6 sm:py-7 sm:px-8">
          <h3 class="font-semibold text-body-2 sm:text-h3 mb-7">
            What will you do with an extra
            <span class="bg-yellow-500 font-extrabold">{{
              roundNumberToShorthandCurrency(currentHomePrice.extraMoney)
            }}</span>
            ?
          </h3>

          <Swiper class="swiper" :options="swiperOption">
            <SwiperSlide>
              <div class="swiper-style">
                <div class="text-body-2 sm:text-h4">
                  Put {{ currentHomePrice.kidsCollege }} kids through college*
                </div>
                <div class="flex-row flex-shrink-0 hidden sm:flex">
                  <div v-for="n in currentHomePrice.kidsCollege" :key="n">
                    <img src="@/assets/icons/graduation.svg" />
                  </div>
                </div>
                <div class="flex flex-row items-center flex-shrink-0 sm:hidden">
                  <span class="text-body-2 sm:text-h4">
                    {{ currentHomePrice.kidsCollege }}x
                  </span>
                  <img src="@/assets/icons/graduation.svg" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="swiper-style">
                <div class="text-body-2 sm:text-h4">
                  Buy {{ currentHomePrice.newCars }} cars*
                </div>
                <div class="flex-row flex-shrink-0 hidden sm:flex">
                  <div v-for="n in currentHomePrice.newCars" :key="n">
                    <img class="px-1" src="@/assets/icons/car.svg" />
                  </div>
                </div>
                <div class="flex flex-row items-center flex-shrink-0 sm:hidden">
                  <span class="text-body-2 sm:text-h4">
                    {{ currentHomePrice.newCars }}x
                  </span>
                  <img src="@/assets/icons/car.svg" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="swiper-style">
                <div class="text-body-2 sm:text-h4">
                  Take {{ currentHomePrice.exoticVacations }} exotic vacations*
                </div>
                <div class="flex-row items-center flex-shrink-0 flex">
                  <span class="text-body-2 sm:text-h4">
                    {{ currentHomePrice.exoticVacations }}x
                  </span>
                  <img src="@/assets/icons/vacation.svg" />
                </div>
              </div>
            </SwiperSlide>
            <div
              class="swiper-button-prev rounded-16 border-maintextcolor border-[1px] bg-creme-50"
              slot="button-prev"
            >
              <img src="@/assets/icons/chevron_big_left.svg" />
            </div>
            <div
              class="swiper-button-next rounded-16 border-maintextcolor border-[1px] bg-creme-50"
              slot="button-next"
            >
              <img src="@/assets/icons/chevron_big_right.svg" />
            </div>
          </Swiper>
        </div>
        <div
          class="col-span-full font-semibold text-body-3 sm:text-body-2 text-right mt-2 sm:mt-3 mb-16 sm:mb-32"
        >
          All numbers are estimates
        </div>
      </div>

      <div
        class="hidden lg:block lg:col-span-4 lg:col-span-4 lg:col-start-2 lg:col-start-2"
      >
        <img src="@/assets/big_investors_cash_grab_vertical.png" />
      </div>

      <div
        class="col-span-full lg:col-start-6 lg:col-span-6 grid grid-cols-1 gap-8 md:gap-12"
      >
        <div>
          <span class="bg-yellow-500 text-body-1 sm:text-h4 font-extrabold mb-2"
            >Too good to be true?</span
          >
          <h3 class="text-h3 font-semibold">Here's how we make it work.</h3>
        </div>
        <div class="col-span-full lg:hidden">
          <img src="@/assets/big_investors_cash_grab_horizontal.png" />
        </div>
        <p class="text-body-2 sm:text-h4">
          Our approach is simple - we enable homebuyers to close on a home
          before it is built. For a flat service fee, we find and connect you to
          new home community opportunities.
        </p>
        <p class="text-body-2 sm:text-h4">
          By committing before breaking ground, homebuyers replace investors
          that would otherwise add up to 20% to the cost of a home. Residents
          essentially become the true 'founders' of their home community.
        </p>
        <p class="text-body-2 sm:text-h4">
          Does this mean you're taking on risk like investors? Nope! In fact,
          there's less financial risk than traditional new construction, because
          we guarantee your price, even if the supply chain makes costs
          skyrocket.
        </p>
        <p class="text-body-1 sm:text-h4 font-semibold">
          <a href="/faq/4" class="text-blue-500">
            See how we protect you from risk ->
          </a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { getCalculatorHomePrices } from "./calculatorData";
import {
  roundNumberToShorthandCurrency,
  currencyNoDecimal,
} from "@/helpers/currencyFormaterHelper.js";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  name: "Calculator",
  components: { Swiper, SwiperSlide },
  data() {
    return {
      homePrices: getCalculatorHomePrices(),
      activeTab: 1,
      currentHomePrice: {},
      item: {},
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 30,
        loop: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        autoplay: {
          delay: 6000,
          disableOnInteraction: false,
        },
      },
    };
  },
  methods: {
    setActiveTab(tab) {
      this.activeTab = tab;
      this.currentHomePrice = this.homePrices.find((item) => item.id === tab);
    },
    roundNumberToShorthandCurrency,
    currencyNoDecimal,
  },
  mounted() {
    this.setActiveTab(1);
  },
  swiper() {
    return this.$refs.mySwiper.$swiper;
  },
};
</script>

<style scoped>
.calc-infoblock-number {
  @apply font-extrabold text-h4 sm:text-h3 text-blue-500;
}
.calc-main-grid {
  @apply col-span-full lg:col-span-10 lg:col-start-2 grid grid-cols-1 grid-rows-3 lg:grid-rows-2 lg:grid-cols-2 gap-8;
  grid-template-rows: repeat(1, minmax(min-content, 1fr)); /* fix this */
  grid-auto-rows: minmax(1fr, auto);
}

.calc-price-button {
  @apply px-5 py-3 sm:px-6 sm:py-4;
}

.active {
  @apply bg-yellow-500 font-semibold rounded-[20px];
}

.swiper-style {
  @apply mx-12 lg:mx-16 text-center flex justify-between items-center flex-row sm:flex-col lg:flex-row overflow-hidden;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  content: "";
}

.swiper-button-prev,
.swiper-button-next {
  width: 40px;
}

.swiper-button-next {
  right: 0px;
}

.swiper-button-prev {
  left: 0px;
}

.what-will-you-do {
  max-width: 980px; /* fix later */
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
</style>
