<template>
  <div class="root relative bg-creme-200">
    <nav :class="navClassObject">
      <div
        class="flex flex-row items-center justify-between px-8 md:px-16 lg:px-16 xl:px-24 py-6 lg:py-8 bg-creme-200"
      >
        <div class="flex flex-row items-center justify-center">
          <router-link to="/" @click.native="closeAll()">
            <div class="flex flex-row items-center h-full">
              <img
                class="hidden lg:block xl:block"
                :src="require('@/assets/images/logo-HF.svg')"
                alt="logo"
              />
              <img
                class="xl:hidden lg:hidden block"
                :src="require('@/assets/homefounder_logo_mobile.svg')"
                alt="logo"
              />
            </div>
          </router-link>
        </div>
        <NavDesktopView @login="navigateItem('/contact')" />
        <PrimaryBaseCta
          text="Contact Us"
          type="secondary"
          fixedSize="small"
          class="xl:hidden lg:hidden block mr-8"
          v-show="!toggleMenu && !isSignedIn"
          @click="navigateItem('/contact')"
        />
        <div
          @click="toggleHidden"
          class="flex flex-row items-center justify-center cursor-pointer lg:hidden xl:hidden min-h-[24px] min-w-[24px]"
        >
          <img
            :src="require('@/assets/icons/mobile_menu_closed.svg')"
            alt="menu-closed"
            v-show="!toggleMenu"
          />
          <img
            :src="require('@/assets/icons/mobile_menu_open.svg')"
            alt="menu-closed"
            v-show="toggleMenu"
          />
        </div>
      </div>

      <div
        :class="[
          toggleMenu
            ? 'opacity-100 overflow-y-auto pt-4'
            : 'h-0 opacity-0 hidden overflow-hidden',
        ]"
        id="mobile-menu"
        class="transition-all duration-200 ease-in-out lg:hidden xl:hidden w-full flex flex-col overflow-y-auto h-screen bg-creme-200"
      >
        <NavMobileView @closeMobile="closeAll()" @login="showAccountModal()" />
      </div>
      <AccountModal
        v-if="isAccountModalShowing"
        @closeAccountModal="closeAccountModal()"
      />
    </nav>
  </div>
</template>

<script>
import { AccountModal } from "@/components/modals";
import NavMobileView from "./NavMobileView.vue";
import NavDesktopView from "./NavDesktopView.vue";
import { isSignedIn } from "@/store/useAuthStore.js";

export default {
  name: "NavBar",
  components: {
    AccountModal,
    NavMobileView,
    NavDesktopView,
    PrimaryBaseCta: () => import("../buttons/PrimaryBaseCta.vue"),
  },
  data() {
    return {
      toggleMenu: false,
      isAccountModalShowing: false,
    };
  },
  methods: {
    toggleHidden() {
      this.toggleMenu = !this.toggleMenu;
    },
    toggleMobileLocation() {
      this.isMobileLocationOpen = !this.isMobileLocationOpen;
    },
    closeAll() {
      this.toggleMenu = false;
    },
    closeAccountModal() {
      this.isAccountModalShowing = false;
    },
    showAccountModal() {
      this.toggleMenu = false;
      setTimeout(() => {
        this.isAccountModalShowing = true;
      }, 100);
    },
    navigateItem(location) {
      this.$router.push({ path: location });
    },
  },
  computed: {
    isSignedIn() {
      return isSignedIn();
    },
    navClassObject() {
      return {
        "nav-menu": true,
        flex: true,
        "flex-col": true,
        "w-full": true,
        "top-0": this.toggleMenu,
        fixed: this.toggleMenu,
        "z-50": this.toggleMenu,
      };
    },
  },
};
</script>
<style scoped>
.nav-right-text {
  @apply text-gray-900 font-semibold text-body-2 cursor-pointer hover:text-blue-500;
}
.login-btn {
  @apply border border-gray-900 rounded-12 text-blue-500 font-semibold text-body-2 bg-creme-200 text-center cursor-pointer;
}

nav,
.nav-menu {
  z-index: 10000;
}
</style>
