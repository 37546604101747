<template>
  <div>
    <div
      class="group cursor-pointer py-6 border-t border-b border-gray-900 border-opacity-20 xl:border-none"
      @click="toggleMobileLocation"
    >
      <div class="flex flex-row items-center justify-between !py-0 border-none">
        <span class="nav-right-text">Locations</span>
        <img
          :class="[isMobileLocationOpen ? 'rotate-180' : '']"
          class="transition-all duration-200 ease-in-out"
          :src="require('@/assets/icons/chevron_down_sm.svg')"
        />
      </div>
      <div
        class="flex flex-col px-4 overflow-hidden"
        v-if="isMobileLocationOpen"
      >
        <div
          v-for="(item, i) in items"
          :key="i"
          class="border-none !pt-6 flex flex-row justify-between"
          @click="navigateItem(item.slug)"
        >
          <div class="flex flex-row w-full items-center justify-between">
            <div class="flex flex-col border-none w-full">
              <span class="nav-right-text">{{ item.title }}</span>
              <span v-if="item.subtitle" class="text-body-2">{{
                item.subtitle
              }}</span>
              <SelectionTag v-if="item.selection" class="mt-2 w-32">
                <template v-slot:text>
                  <span class="font-extrabold text-body-3">{{
                    item.selection.text
                  }}</span>
                </template>
              </SelectionTag>
            </div>
            <img :src="require('@/assets/icons/chevron_right_sm.svg')" />
          </div>
        </div>
        <div
          class="border-none !pt-6 flex flex-row justify-between"
          @click="navigateItem('/locations')"
        >
          <span class="nav-right-text">More locations</span>
          <img :src="require('@/assets/icons/chevron_right_sm.svg')" />
        </div>
      </div>
    </div>
    <div class="py-6 border-b border-gray-900 border-opacity-20 xl:border-none">
      <router-link
        class="nav-right-text"
        to="/how-it-works"
        @click.native="closeAll()"
        >How it works</router-link
      >
    </div>
    <div class="py-6 border-b border-gray-900 border-opacity-20 xl:border-none">
      <router-link class="nav-right-text" to="/about" @click.native="closeAll()"
        >About us</router-link
      >
    </div>
    <div class="flex justify-center py-6 border-none">
      <NavBarCta
        @specialist="navigateItem('/get-notified')"
        @dashboard="navigateItem('/dashboard')"
        @login="navigateItem('/contact')"
      />
    </div>
  </div>
</template>
<script>
import NavBarCta from "./NavBarCta.vue";
import SelectionTag from "@/components/tags/SelectionTag.vue";
export default {
  name: "NavMobileMarketing",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    NavBarCta,
    SelectionTag,
  },
  data() {
    return {
      isMobileLocationOpen: false,
    };
  },
  methods: {
    toggleMobileLocation() {
      this.isMobileLocationOpen = !this.isMobileLocationOpen;
    },
    navigateItem(slug) {
      this.$router.push({
        path: `${slug}`,
      });
      this.isMobileLocationOpen = false;
      const _this = this;
      setTimeout(() => {
        _this.closeAll();
      }, 100);
    },
    closeAll() {
      this.isMobileLocationOpen = false;
      const _this = this;
      setTimeout(() => {
        _this.$emit("closeMobile");
      }, 100);
    },
    login() {
      this.$emit("login");
    },
  },
};
</script>
<style scoped>
.nav-right-text {
  @apply text-gray-900 font-semibold text-body-2 cursor-pointer hover:text-blue-500;
}
.login-btn {
  @apply border border-gray-900 rounded-12 text-blue-500 font-semibold text-body-2 bg-creme-200 text-center cursor-pointer;
}
</style>
