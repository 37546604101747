<template>
  <div class="flex flex-row flex-grow items-center justify-center">
    <div
      class="flex flex-row flex-grow items-center justify-center"
      v-if="isMarketingSiteOnly"
    >
      <PrimaryBaseCta
        text="Contact a Home Specialist"
        type="secondary"
        fixedSize="small"
        class="w-1/2 xl:w-full"
        @click="onClickHandler('specialist')"
      />
    </div>
    <div class="flex flex-row flex-grow items-center justify-center" v-else>
      <PrimaryBaseCta
        text="My dashboard"
        type="secondary"
        fixedSize="small"
        endImage="icons/dashboard.svg"
        class="w-1/2 lg:w-1/4 xl:w-full"
        v-if="monitorAuth"
        @click="onClickHandler('dashboard')"
      />

      <PrimaryBaseCta
        text="Contact us"
        type="secondary"
        fixedSize="small"
        class="w-full"
        v-if="!monitorAuth"
        @click="onClickHandler('login')"
      />
    </div>
  </div>
</template>
<script>
import { isMarketingOnly } from "@/featureflags/useFlags.js";
import { isSignedIn } from "@/store/useAuthStore.js";
import PrimaryBaseCta from "@/components/buttons/PrimaryBaseCta.vue";
export default {
  components: {
    PrimaryBaseCta,
  },
  computed: {
    monitorAuth() {
      return isSignedIn();
    },
    isMarketingSiteOnly() {
      return isMarketingOnly;
    },
  },
  methods: {
    onClickHandler(event) {
      this.$emit(event);
    },
  },
};
</script>
