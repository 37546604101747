<template>
  <global-section>
    <div
      class="relative rounded-32 border border-gray-900 border-opacity-20 w-full"
    >
      <div
        class="flex flex-col md:flex-row justify-between items-center p-8 md:p-12"
      >
        <div>
          <h3 class="text-h3 md:text-h3 font-semibold">More questions?</h3>

          <p class="py-8 text-body-1 md:text-h4">
            Fill out a simple form to get in touch with us
          </p>
        </div>

        <PrimaryBaseCta
          text="Get in touch"
          endImage="ico_circle_arrow_white.svg"
          class="w-56 h-16 text-h4 flex justify-center items-center"
          @click="goToContact"
        />
      </div>

      <div class="weird-thing"></div>
      <div class="circle"></div>
    </div>
  </global-section>
</template>

<script>
import { PrimaryBaseCta, GlobalSection } from "@/components";

export default {
  name: "MoreQuestions",
  components: {
    PrimaryBaseCta,
    GlobalSection,
  },
  methods: {
    goToContact() {
      this.$router.push({ name: "Contact" });
    },
  },
};
</script>

<style scoped>
.circle {
  @apply absolute w-14 h-14 bottom-[-24px] right-[-24px] md:bottom-[-48px] md:right-[-48px] md:w-24 md:h-24 bg-yellow-500 rounded-100 opacity-70;
}

.weird-thing {
  clip-path: polygon(0 45%, 100% 0%, 100% 100%, 0% 100%);
  @apply absolute w-[41px] h-[36px] top-[-15px] left-[-18px] md:top-[-40px] md:left-[-50px] md:w-[83px] md:h-[72px] bg-blue-300 opacity-70;
}
</style>
