<template>
  <div id="app">
    <NavBar />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import NavBar from "@/components/nav/NavBar.vue";
import Footer from "@/components/footer/Footer.vue";

export default {
  name: "App",
  components: {
    NavBar,
    Footer,
  },
};
</script>

<style>
#app {
  display: flex;
  flex-direction: column;
  height: 100%;
  @apply text-maintextcolor overflow-x-hidden lg:overflow-x-auto;
}

hr {
  @apply border-gray-900 opacity-20;
}

.max-container a:hover {
  @apply bg-blue-100;
}

.main-container {
  @apply container px-8 md:px-16 lg:px-16 xl:max-w-[1440px];
}

.max-container {
  @apply xl:max-w-[1312px] mx-auto;
}

.main-grid {
  @apply grid grid-cols-6 gap-4 mx-8 md:mx-16 lg:mx-16 xl:mx-24  lg:grid-cols-12 lg:gap-4 lg:mx-16 xl:grid-cols-12 xl:gap-8 xl:mx-16;
}
</style>
