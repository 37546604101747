<template>
  <div class="bg-yellow-500">
    <div class="max-container">
      <div class="main-grid py-12 md:py-16">
        <div
          class="col-span-full xl:col-span-10 xl:col-start-2 flex flex-col lg:flex-row justify-between items-center"
        >
          <div
            class="flex flex-row justify-between relative w-full lg:w-max mb-8 lg:mb-0"
          >
            <h4
              class="text-h4 font-semibold text-center lg:text-right mx-auto max-w-full lg:max-w-[126px]"
            >
              Our quality promise.
            </h4>
            <img
              class="absolute bottom-[-64px] right-[-120px] hidden lg:block"
              src="@/assets/icons/pinky_swear.svg"
              alt="Pinky Swear hand icon"
            />
          </div>
          <div class="strip-item lg:ml-24 mb-8 lg:mb-0">
            <h4>Award-winning</h4>
            <span>home designs</span>
          </div>
          <div class="strip-item mb-8 lg:mb-0">
            <h4>High-Quality</h4>
            <span>construction and finishings</span>
          </div>
          <div class="strip-item">
            <h4>2 year</h4>
            <span>warranty</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "YellowStrip",
  methods: {
    onClick() {
      this.$router.push({
        path: "/locations/hollysprings",
      });
    },
  },
};
</script>

<style scoped>
.strip-item {
  @apply flex flex-col text-center lg:text-left;
}

.strip-item h4 {
  @apply font-semibold text-body-1 lg:text-h4;
}

.strip-item span {
  @apply text-body-2 lg:text-body-1;
}
</style>
