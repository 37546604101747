import Vue from "vue";

const MARKETING = Symbol("marketing");
const APP = Symbol("app");

const DASHBOARD = Symbol("dashboard");
const MY_HOME = Symbol("my_home");
const NONE = Symbol("none");

const store = Vue.observable({ navType: APP, appNavLocation: NONE });

const actions = {
  setNavigationToMarketing(bool) {
    if (bool) {
      store.navType = MARKETING;
    } else {
      store.navType = APP;
    }
  },
  isMarketingNavigation() {
    return store.navType == MARKETING;
  },
  isAppNavigation() {
    return store.navType == APP;
  },
  setNoneSelected() {
    store.appNavLocation = NONE;
  },
  setDashboardSelected() {
    store.appNavLocation = DASHBOARD;
  },
  isDashboardSelected() {
    return store.appNavLocation == DASHBOARD;
  },
  setMyHomeSelected() {
    store.appNavLocation = MY_HOME;
  },
  isMyHomeSelected() {
    return store.appNavLocation == MY_HOME;
  },
};

export { store, actions };
