<template>
  <div class="max-container">
    <div class="main-grid">
      <div class="layout-grid-row text-h3 md:text-h1 font-semibold mt-16 pt-16">
        <h1>Questions?</h1>
        <h2>We're here to help.</h2>
      </div>

      <div class="layout-grid-row">
        <MainSection />
      </div>

      <div class="layout-grid-row">
        <MoreQuestions />
      </div>
    </div>
  </div>
</template>

<script>
import { MoreQuestions, MainSection } from "@/components";
export default {
  name: "Faq",
  components: {
    MoreQuestions,
    MainSection,
  },
};
</script>

<style scoped>
.layout-grid-row {
  @apply col-span-full;
}
</style>
