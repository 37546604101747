<template>
  <div class="max-container overflow-hidden sm:overflow-visible bg-creme-200">
    <div class="main-grid mt-5 mb-24 lg:mt-24 lg:mb-32 lg:mx-0">
      <div class="col-span-full lg:col-span-5 lg:col-start-2">
        <div class="mb-6 lg:mb-12 text-h3 lg:text-h1 font-semibold">
          <h1>New homes for</h1>
          <span class="text-blue-500"> up to 15% below appraisal </span>
        </div>
        <p class="text-body-2 md:text-h4">
          Close on new construction home before builders break ground, and pay
          up to $80K less than the appraised value.
        </p>
        <br />
        <p class="mb-2 lg:mb-4 text-body-2 md:text-h4">
          Plus, say goodbye to bidding wars with our guaranteed prices!
        </p>
        <div class="flex flex-wrap mb-4">
          <PrimaryBaseCta
            text="Contact us"
            class="mr-4 mt-5 whitespace-nowrap"
            fixedSize="medium"
            endImage="ico_circle_arrow_white.svg"
            @click="navigateToContact"
          />

          <PrimaryBaseCta
            type="secondary"
            class="whitespace-nowrap mt-5"
            text="View Douglasville homes"
            fixedSize="medium"
            endImage="icons/arrow_right_blue.svg"
            @click="navigateToHollySprings"
          />
        </div>
        <p class="text-body-2 mb-8 lg:mb-0">
          More locations coming to the Atlanta metropolitan area in 2023.
        </p>
      </div>
      <div
        class="col-span-full lg:col-span-5 lg:col-start-7 hero-image-container"
      >
        <div class="hero-image" />
        <div class="home-page-rectangle">
          <div
            class="w-full h-full pb-5 px-6 flex flex-col justify-end items-end"
          >
            <p class="text-blue-500 font-extrabold text-body-2 sm:body-1">
              Coming Soon
            </p>
            <p class="text-gray-900 font-semibold text-h4 sm:text-h3">
              May 2022
            </p>
            <p class="text-gray-900 font-semibold text-body-2 sm:body-1">
              Douglasville, GA
            </p>
          </div>
        </div>
        <div class="yellow-circle"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { PrimaryBaseCta } from "@/components";

export default {
  name: "StartingPage",
  components: {
    PrimaryBaseCta,
  },
  methods: {
    navigateToHollySprings() {
      this.$router.push({
        path: "/locations/hollysprings",
      });
    },
    navigateToContact() {
      this.$router.push({
        path: "/contact",
      });
    },
  },
};
</script>

<style scoped>
.hero-image-container {
  @apply w-full mx-auto h-[400px] md:h-[500px] block lg:w-[80%] lg:h-full relative lg:mr-0 lg:ml-auto z-10;
}

.hero-image {
  background: url("~@/assets/images/home_page_house_HQ.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @apply rounded-3xl relative z-10 block w-full h-full;
}

.yellow-circle {
  @apply absolute bg-yellow-500 rounded-full w-60 h-60  left-auto top-auto right-[-75px] bottom-[-70px]  lg:w-80 lg:h-80 lg:top-auto lg:bottom-[-70px] lg:left-[-100px];
}

.home-page-rectangle {
  clip-path: polygon(0 25%, 100% 0%, 100% 100%, 0% 100%);
  @apply absolute left-auto top-auto right-[-16px] bottom-[-25px] lg:top-auto lg:right-auto lg:-left-8 lg:bottom-8 z-10 text-black bg-creme-200 w-48 h-36 sm:w-52 sm:h-40;
}

.check-circle {
  @apply bg-blue-300 rounded-full mr-3 w-6 h-6 mt-1 sm:mt-0;
}
</style>
