const models = [
  {
    id: 1,
    name: 600000,
    upfrontHomeSavings: 120000,
    appraisedHomeValue: 600000,
    homeFounderPrice: 480000,
    monthlyMortageSaving: 555,
    totalInterestSaving: 79800,
    extraMoney: 199800.0,
    kidsCollege: 5,
    newCars: 8,
    exoticVacations: 40,
  },
  {
    id: 2,
    name: 500000,
    upfrontHomeSavings: 100000,
    appraisedHomeValue: 500000,
    homeFounderPrice: 400000,
    monthlyMortageSaving: 460,
    totalInterestSaving: 65600,
    extraMoney: 165600.0,
    kidsCollege: 4,
    newCars: 7,
    exoticVacations: 33,
  },
  {
    id: 3,
    name: 400000,
    upfrontHomeSavings: 80000,
    appraisedHomeValue: 400000,
    homeFounderPrice: 320000,
    monthlyMortageSaving: 367,
    totalInterestSaving: 52120,
    extraMoney: 132120.0,
    kidsCollege: 4,
    newCars: 5,
    exoticVacations: 26,
  },
];

export const getCalculatorHomePrices = () => models;
