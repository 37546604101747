export const questionFilters = [
  { id: 0, text: "All FAQs", active: true },
  { id: 1, text: "How does HomeFounder work?", active: false },
  { id: 2, text: "Financing", active: false },
  { id: 3, text: "Reserving, Contracting, and Closing", active: false },
  { id: 4, text: "Costs", active: false },
  { id: 5, text: "Construction", active: false },
  { id: 6, text: "Contact information", active: false },
];

/* Relationship between filters and question-answer pairs
 * is defined with questionFiltersId property
 */
export const faqArray = [
  {
    id: 0,
    questionFiltersId: 1,
    question: "What is HomeFounder exactly?",
    answer:
      "HomeFounder is a new platform that helps aspiring homeowners identify and develop new homes and communities. We are not a broker, developer, builder, or bank— but we work directly with the best to create unheard-of deals for buyers like you.<br><br>HomeFounder finds lots in desirable locations, develops them, and connects you to reputable homebuilders. By working with us and cutting out traditional investors, you save up to 15% off the appraised value of your home and even more in interest.",
    active: false,
  },
  {
    id: 1,
    questionFiltersId: 1,
    question: "Who is HomeFounder for?",
    answer:
      "HomeFounder serves normal Americans who are looking for their next primary residence. We aim to build strong communities and do not sell to home flippers, landlords, or vacationers.",
    active: false,
  },
  {
    id: 2,
    questionFiltersId: 1,
    question:
      "How is it possible for HomeFounder to sell homes below appraised value?",
    answer:
      "In typical residential developments, the big investors who fund land development and construction demand outsized returns. By identifying homebuyers and securing their commitment early, HomeFounder is able to exchange those investor returns for a substantial discount to homebuyers.",
    active: false,
  },
  {
    id: 3,
    questionFiltersId: 1,
    question: "This seems too good to be true. Why should I trust you?",
    answer:
      'Many people ask us, "Why would HomeFounder sell homes below appraisal?"<br><br>The short answer is: because we believe the real estate industry needs to better align incentives with homebuyers. Not only do we offer high-quality homes below appraisal, we intentionally take a reasonable, fixed fee (instead of a percentage of home price or value) so we have no incentive to upsell or drive prices higher.<br><br>This means less revenue for us in the short term, but better business in the long term.',
    active: false,
  },
  {
    id: 4,
    questionFiltersId: 1,
    question:
      "If I invest early, am I taking on the risk that the development might fall through? How do you protect me?",
    answer:
      "We work with established builders for a fixed fee on guaranteed max price contracts. These contracts come with a completion guarantee, so you can rest assured that the homes will be built.<br><br>Additionally, your mortgage rate is locked in when you sign a Purchase & Sale agreement, so you can budget with confidence in your monthly payment. HomeFounder builds substantial additional contingencies into development to account for any unforeseen cost increases or delays.",
    active: false,
  },
  {
    id: 5,
    questionFiltersId: 1,
    question: "Where does HomeFounder operate?",
    answer:
      "We currently operate in the Atlanta metropolitan area, but will be expanding soon. Want us to come to another location? <a href='/locations' class='text-blue-500'>Let us know</a>.",
    active: false,
  },
  {
    id: 6,
    questionFiltersId: 1,
    question: "Are the homes lower quality because they’re cheaper?",
    answer:
      "We don’t cut corners on home quality; we only cut out margins that pad the pockets of institutional investors.",
    active: false,
  },
  {
    id: 7,
    questionFiltersId: 1,
    question: "Do I need a real estate agent to buy new construction?",
    answer:
      "No, you do not need an agent to purchase a new home with HomeFounder. You are welcome to work with one if you choose but will be responsible for paying their fees.",
    active: false,
  },

  {
    id: 8,
    questionFiltersId: 2,
    question: "How does financing work?",
    answer:
      "We have partnered with Affinity Bank (link) to help our customers navigate the process of securing a construction-to-perm loan within the required timeframes.<br><br>Before you can reserve your home, HomeFounder requires pre-approval (through our financing partner) which allows for quicker final approval once your home is reserved. Pre-approval, unlike pre-qualification, is a hard pull on your credit.<br><br>Once your home is reserved, our financing partner will complete the loan approval process and coordinate all required details prior to closing.",
    active: false,
  },
  {
    id: 9,
    questionFiltersId: 2,
    question: "What terms are supported by the lender?",
    answer:
      "Our financing partner, Affinity Bank, offers construction-to-perm 15-year fixed and 5/1 and 7/1 adjustable rate mortgages.",
    active: false,
  },
  {
    id: 10,
    questionFiltersId: 2,
    question: "What is a single-close construction to perm loan?",
    answer:
      "Construction-to-permanent loans allow you to finance construction, along with your completed home. When construction concludes, it automatically converts into a permanent mortgage.<br><br>Under this structure, you’ll only have to pay one set of closing costs and your interest rate will be locked in from the point of application.",
    active: false,
  },

  {
    id: 11,
    questionFiltersId: 3,
    question: "Why is there a short reservation window?",
    answer:
      "Every community has a limited-time home site reservation & closing period. This is the maximum time that HomeFounder can guarantee your home price, due to fluctuating construction and material costs. ",
    active: false,
  },
  {
    id: 12,
    questionFiltersId: 3,
    question:
      "What’s the difference between the Purchase & Sale agreement and what happens at closing?",
    answer:
      "A Purchase & Sale Agreement (PSA) is a binding contract executed between you and the builder. It defines the terms of the transaction.<br><br>On closing day, the transaction will be finalized and all necessary documentation will be executed by you, the lender, and the closing attorney. Your downpayment will be due at closing.",
    active: false,
  },
  {
    id: 13,
    questionFiltersId: 3,
    question: "Can I see my home before closing?",
    answer:
      "When you close, the home won’t be built yet, so there isn’t anything to see! All prospective homeowners will receive regular updates throughout the construction process. If homeowners wish they can schedule in-person visit to see their home ‘s progress.",
    active: false,
  },

  {
    id: 14,
    questionFiltersId: 4,
    question: "What do I pay at closing?",
    answer:
      "At closing, you will pay the downpayment, which will approximate 20% of the purchase value of your completed home. Additional closing costs disclosed on the PSA and agent fees, if applicable, will also be paid by buyer at this point.",
    active: false,
  },
  {
    id: 15,
    questionFiltersId: 4,
    question: "What is the HomeFounder fee?",
    answer: "HomeFounder charges a flat rate of $11,000 per home.",
    active: false,
  },
  {
    id: 16,
    questionFiltersId: 4,
    question: "How is the home value estimated?",
    answer:
      "The estimated home value displayed on the website is based on a neutral third party appraisal.",
    active: false,
  },
  {
    id: 17,
    questionFiltersId: 4,
    question: "How do you guarantee purchase price given market volatility?",
    answer:
      "We work with a third party appraiser to get a realistic idea of the value of each of our developments. On top of this we work with our third party builder to price out the build for the homes. Within these models we create a contingency in case home costs experience any issues. Once this is completed, we add a buffer for market volatility for our raw costs. If, in the unlikely scenario we run over these costs, your price is still guaranteed.<br><br>There is a contingency in case construction materials increase by more than TK%. While unlikely, supply chain volatility may delay or significantly change the cost of materials. If this happens, we will work with you on a case-by-case basis to determine the best course of action.",
    active: false,
  },

  {
    id: 18,
    questionFiltersId: 5,
    question: "Who is building my home? How do you vet them?",
    answer:
      "We contract with reputable local and national builders with whom we have long relationships. Beyond the basics of proper licensure and insurance we know from experience they are great fits for our vision and values. ",
    active: false,
  },
  {
    id: 19,
    questionFiltersId: 5,
    question: "Can I check on construction as my home is built?",
    answer: "TO FILL",
    active: false,
  },
  {
    id: 20,
    questionFiltersId: 5,
    question: "What if there’s an issue with the build quality or timeline?",
    answer:
      "While unexpected issues arise, our Home Specialists are a resource for you throughout the construction process. Reach out via email and we’ll work with you and the builder to resolve the issue. <br><br> Once the home is built, all HomeFounder homes are covered by a third party warranty. For our first community, all the homes are covered by structural warranty provider" +
      "<a href='https://www.2-10.com/'>www.2-10.com/</a>" +
      ". You can file issues here. (link)",
    active: false,
  },

  {
    id: 21,
    questionFiltersId: 6,
    question: "Who do I contact with questions?",
    answer:
      'Your local Home Specialist is the best person to direct questions to. They can also coordinate questions with our lender or builder, as needed. Reach out to <a class="text-body-2 text-blue-500 font-semibold" href="mailto:homespecialists@homefounder.com">homespecialists@homefounder.com</a>',
    active: false,
  },
];
