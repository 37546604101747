module.exports = {
  mode: "jit",
  purge: ["./index.html", "./src/**/*.{vue,js,ts,jsx,tsx}"],
  darkMode: false,
  theme: {
    screens: {
      sm: "478px",
      md: "768px",
      lg: "1024px",
      xl: "1280px",
      "2xl": "1536px",
    },
    /*
      Spacing:
      1	0.25rem	4px	
      2	0.5rem	8px	
      3	0.75rem	12px	
      4	1rem	16px	
      5	1.25rem	20px	
      6	1.5rem	24px	
      7 1.75rem 28px
      8	2rem	32px	
      10	2.5rem	40px	<-- SHOULD NOT BE IN DESIGN
      12	3rem	48px	
      16	4rem	64px	
      20	5rem	80px	<-- SHOULD NOT BE IN DESIGN
      24	6rem	96px	
      32	8rem	128px	
      40	10rem	160px	<-- SHOULD NOT BE IN DESIGN
      48	12rem	192px	<-- SHOULD NOT BE IN DESIGN
      56	14rem	224px	<-- SHOULD NOT BE IN DESIGN
      64	16rem	256px<-- SHOULD NOT BE IN DESIGN

      Corner Radii
      8   rounded-lg
      12  rounded-xl 
      16  rounded-2xl
      24  rounded-3xl
      32
      100  rounded-full
    */
    fontWeight: {
      semibold: "600",
      regular: "400",
      extralight: "200",
      bold: "700",
      extrabold: "800"
    },
    extend: {
      spacing: {
        7: "28px"
      },
      borderRadius: {
        "4": "4px",
        "8": "8px",
        "12": "12px",
        "16": "16px",
        "24": "24px",
        "28" : "28px",
        "32": "32px",
        "48": "48px",
        "64": "64px",
        "100": "9999px"
      },
      fontSize: {
        "h1": ["64px", "88px"],
        "h2": ["48px", "66px"],
        "h3": ["32px", "44px"],
        "h4": ["24px", "32px"],
        "body-1": ["20px", "28px"], 
        "body-2": ["16px", "24px"],
        "body-3": ["12px", "16px"],
        "button-small": ["16px", "20px"],
        "button-medium": ["20px", "24px"],
        "button-large": ["24px", "28px"]
      },
      colors: {
        main: "#F5F0E9",
        maintextcolor: "#2C2525",
        activegreen: "#42B983",
        gray: {
          900: "#2C2525",
          500: "#878383"
        },
        black: "#2C2525",
        yellow: {
          500: "#F5DD7B"
        },
        blue: {
          600: "#264E70",
          500: "#264E70",
          300: "#C3D6E1",
          200: "#DAE4EA",
          100: "#E9F2F5"
        },
        darkblue: "#00588A",
        creme: {
          50: "#FCFBF8",
          100: "#FCFBF8",
          200: "#F5F0E9",
          300: "#EAE4D6",
          500: "#DDD5C1",
        },
        green: {
          500: "#26705E"
        },
        red: {
          500: "#B84438"
        },
        hovered: {
          "0.1": "rgba(0, 0, 0, 0.1)",
          "0.05": "rgba(0, 0, 0, 0.05)"
        }
      },
      boxShadow: {
        "primary-cta": '0px 4px 12px rgba(0, 0,0, 0.1)',
        "primary-hover-cta": '0px 8px 16px rgba(0, 0,0, 0.1)',
      },
      space: {
        "hf-lg": "32px",
        "hf-sm": "16px",
      },
      minWidth: {
        "hf-container": "1376px",
      },
      height: {
        "hf-screen": "calc(100vh - 5rem)",
      },
      container: {
        center: true,
      },
    },
  },
  variants: {
    extend: {},
    width: ["responsive", "hover", "focus"],
    height: ["responsive", "hover", "focus"]
  },
  plugins: [require("tw-elements/dist/plugin")],
};
