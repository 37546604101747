import { HUBSPOT_PAGES, HUBSPOT_DATA, HUBSPOT_API_URL } from "@/constants";
import { getCookieValue } from "./cookies";

const {
  OBJECT_TYPE_ID,
  FIRST_NAME,
  LAST_NAME,
  EMAIL,
  IDEAL_MOVE_DATE,
  IS_AGENT,
  HOME_MODEL_INTEREST,
  IS_FTHB,
  LEAD_FORM_COMMENTS,
  LOCATIONS_INTERESTED,
  COOKIE_NAME,
} = HUBSPOT_DATA;

const getIP = async () => {
  const ipServiceUrl = `https://ipgeolocation.abstractapi.com/v1/?api_key=${process.env.VUE_APP_IPGEOLOCATION_API_KEY}`;
  return fetch(ipServiceUrl).then((res) => res.json());
};

const prepareFormData = async (page, formData) => {
  const {
    firstname,
    lastname,
    email,
    ideal_move_date,
    lead_form_comments,
    is_agent,
    home_model_interest,
    is_fthb,
    locations_interested,
  } = formData;

  const sharedData = [
    {
      objectTypeId: OBJECT_TYPE_ID,
      name: FIRST_NAME,
      value: firstname,
    },
    {
      objectTypeId: OBJECT_TYPE_ID,
      name: LAST_NAME,
      value: lastname,
    },
    {
      objectTypeId: OBJECT_TYPE_ID,
      name: EMAIL,
      value: email,
    },
    {
      objectTypeId: OBJECT_TYPE_ID,
      name: IDEAL_MOVE_DATE,
      value: ideal_move_date || "",
    },
    {
      objectTypeId: OBJECT_TYPE_ID,
      name: IS_AGENT,
      value: is_agent,
    },
  ];
  const dynamicData = [];
  let contextData = {};
  let api_url = null;

  const trackingCookie = getCookieValue(COOKIE_NAME);
  if (!trackingCookie) {
    throw new Error("Hubspot tracking cookie missing!");
  } else {
    contextData.hutk = trackingCookie;
  }

  const geolocationData = await getIP();
  if (geolocationData) {
    contextData.ipAddress = geolocationData.ip_address;
  }

  switch (page) {
    case HUBSPOT_PAGES.HOLLYSPRINGS:
      dynamicData.push(
        {
          objectTypeId: OBJECT_TYPE_ID,
          name: HOME_MODEL_INTEREST,
          value: home_model_interest || "",
        },
        {
          objectTypeId: OBJECT_TYPE_ID,
          name: IS_FTHB,
          value: is_fthb || "",
        }
      );
      contextData = {
        ...contextData,
        pageUri: window.location.href,
        pageName: document.title,
      };
      api_url = HUBSPOT_API_URL.HOLLYSPRINGS;
      break;

    case HUBSPOT_PAGES.LOCATIONS:
      dynamicData.push(
        {
          objectTypeId: OBJECT_TYPE_ID,
          name: LEAD_FORM_COMMENTS,
          value: lead_form_comments || "",
        },
        {
          objectTypeId: OBJECT_TYPE_ID,
          name: LOCATIONS_INTERESTED,
          value: locations_interested || "",
        }
      );
      contextData = {
        ...contextData,
        pageUri: "www.homefounder.com/locations",
        pageName: "Locations",
      };
      api_url = HUBSPOT_API_URL.LOCATIONS;
      break;

    case HUBSPOT_PAGES.CONTACT:
      dynamicData.push({
        objectTypeId: OBJECT_TYPE_ID,
        name: LEAD_FORM_COMMENTS,
        value: lead_form_comments || "",
      });
      contextData = {
        ...contextData,
        pageUri: "www.homefounder.com/contact",
        pageName: "Contact Us",
      };
      api_url = HUBSPOT_API_URL.CONTACT;
      break;
  }

  return [
    {
      fields: [...sharedData, ...dynamicData],
      context: contextData,
    },
    api_url,
  ];
};

export const postToHubspot = async (page, data) => {
  const [dataToSend, api_url] = await prepareFormData(page, data);

  if (!api_url) throw new Error("API URL is not defined!");

  return fetch(api_url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(dataToSend),
  })
    .then((res) => res.json())
    .then((data) => {
      // Handle Hubspot API's internal email validator
      if (data.errors) {
        data.errors.forEach((error) => {
          if (error.errorType === "INVALID_EMAIL")
            throw new Error("Invalid email address");
        });
      }

      // This marks successfull form submission on Hubspot's side
      if (data.inlineMessage === "Thanks for submitting the form.") {
        return { success: true };
      } else {
        console.error("Problem sending data to Hubspot");
      }
    });
};
