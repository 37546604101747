import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./index.css";
import lodashGet from "lodash/get";
import "tw-elements";
import VModal from "vue-js-modal";

import Amplify, { API, Logger } from "aws-amplify";
import awsconfig from "./aws-exports";
import { store, actions } from "./store/useNavigationStore.js";
import usePreReleaseActions from "./store/useReleaseDateStore.js";

Amplify.configure({
  ...awsconfig,
});

Vue.use(VModal);
// TODO: need to pass log level as env var
const logger = new Logger("app", "DEBUG");
Vue.prototype.$logger = logger;
Vue.prototype.$API = API;
Vue.prototype.$get = lodashGet;

Vue.prototype.$navStore = store;
Vue.prototype.$navActions = actions;
Vue.prototype.$preReleaseActions = usePreReleaseActions;

const currencyFormatter = Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});
Vue.prototype.$currencyFormatter = currencyFormatter;

const currencyNoDecimal = Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});
Vue.prototype.$currencyNoDecimal = currencyNoDecimal;

Vue.config.ignoredElements = [/amplify-\w*/];
Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
