<template>
  <div class="flex flex-row items-center justify-end">
    <div
      class="hidden xl:flex lg:flex flex-row items-center justify-end space-x-12"
    >
      <div class="flex flex-col items-center relative group">
        <div class="flex flex-row justify-between space-x-3">
          <div class="nav-right-text">Locations</div>
          <svg
            width="12"
            height="6"
            viewBox="0 0 12 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="self-center group-hover:rotate-180 group-hover:transition-all fill-current text-gray-900 group-hover:text-blue-500 duration-200 ease-in-out"
          >
            <path
              d="M6.00033 5.91666L11.8337 0.0833225L0.166992 0.0833225L6.00033 5.91666Z"
            />
          </svg>
        </div>
        <div class="absolute top-12 min-w-[320px]">
          <BottomToolTip class="-mt-96 group-hover:mt-0 delay-100">
            <template v-slot:content>
              <div class="flex flex-col items-center justify-between">
                <div
                  class="w-full flex flex-row justify-between cursor-pointer"
                  v-for="(item, i) in items"
                  @click="navigateItem(item.slug)"
                  :key="i"
                >
                  <router-link :to="`${item.slug}`">
                    <div class="flex flex-col">
                      <span class="font-semibold text-body-1 text-gray-900">{{
                        item.title
                      }}</span>
                      <span v-if="item.subtitle" class="text-body-2">{{
                        item.subtitle
                      }}</span>
                    </div>
                    <SelectionTag v-if="item.selection" class="mt-2">
                      <template v-slot:text>
                        <span class="font-semibold text-body-3">{{
                          item.selection.text
                        }}</span>
                      </template>
                    </SelectionTag>
                  </router-link>
                  <img :src="require('@/assets/icons/chevron_big_right.svg')" />
                </div>
              </div>
              <div
                class="w-full flex flex-row justify-between cursor-pointer"
                @click="navigateItem('/locations')"
              >
                <router-link to="/locations">
                  <span class="font-semibold text-body-1 text-gray-900"
                    >More locations</span
                  >
                </router-link>
                <img :src="require('@/assets/icons/chevron_big_right.svg')" />
              </div>
            </template>
          </BottomToolTip>
          <!-- group-hover will not work properly with h-auto so leaving it for now -->
        </div>
      </div>
      <router-link class="nav-right-text" to="/how-it-works"
        >How it works</router-link
      >
      <router-link class="nav-right-text" to="/about">About us</router-link>
      <NavBarCta
        @specialist="navigateItem('/get-notified')"
        @dashboard="navigateItem('/dashboard')"
        @login="navigateItem('/contact')"
      />
    </div>
  </div>
</template>
<script>
import NavBarCta from "./NavBarCta.vue";
import BottomToolTip from "@/components/tooltips/BottomToolTip.vue";
import SelectionTag from "@/components/tags/SelectionTag.vue";
export default {
  name: "NavDesktopMarketing",
  components: {
    NavBarCta,
    BottomToolTip,
    SelectionTag,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    navigateItem(slug) {
      this.$router.push({
        path: `${slug}`,
      });
    },
    login() {
      this.$emit("login");
    },
  },
};
</script>
<style scoped>
.nav-right-text {
  @apply text-gray-900 font-semibold text-body-2 cursor-pointer hover:text-blue-500;
}
.login-btn {
  @apply border border-gray-900 rounded-12 text-blue-500 font-semibold text-body-2 bg-creme-200 text-center cursor-pointer;
}
</style>
