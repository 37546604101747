<template>
  <div>
    <StartingPage />
    <YellowStrip />
    <global-section type="dark">
      <Calculator />
    </global-section>
    <global-section type="light">
      <Homebuying />
    </global-section>
    <global-section type="dark">
      <UpcomingLocations />
    </global-section>
    <global-section type="founder">
      <FounderPage />
    </global-section>
  </div>
</template>

<script>
import {
  StartingPage,
  UpcomingLocations,
  FounderPage,
  Calculator,
  YellowStrip,
  Homebuying,
  GlobalSection,
} from "@/components";

export default {
  name: "Home",
  components: {
    StartingPage,
    UpcomingLocations,
    FounderPage,
    Calculator,
    YellowStrip,
    Homebuying,
    GlobalSection,
  },
};
</script>
