<template>
  <label class="input-container-base">
    <span class="span-wrapper left-0 mr-5" v-if="startImage">
      <img :src="require('@/assets/' + startImage)" v-if="startImage" />
    </span>
    <input
      class="text-input"
      :placeholder="placeholder"
      :type="inputType"
      :value="value"
      name="search"
      :required="required"
      @input="updateValue($event.target.value)"
    />
    <span class="span-wrapper right-0 ml-5" v-if="endImage">
      <img :src="require('@/assets/' + endImage)" v-if="endImage" />
    </span>
  </label>
</template>

<script>
export default {
  name: "TextInput",
  props: {
    value: {
      type: String,
    },
    placeholder: {
      type: String,
      default: "",
    },
    startImage: {
      type: String,
      default: null,
    },
    endImage: {
      type: String,
      default: null,
    },
    inputType: {
      type: String,
      default: "text",
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    updateValue(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style scoped>
.input-container-base {
  @apply relative flex flex-row items-center justify-center border border-gray-500 rounded-8 lg:p-6 px-4 py-3 focus-within:bg-creme-100;
}
.span-wrapper {
  @apply relative inset-y-0 flex items-center w-6 h-6 py-1;
}
.span-wrapper > img {
  @apply w-6 h-6;
}
.text-input {
  @apply block w-full rounded-8 border-0 bg-transparent placeholder-gray-500 text-body-1 xl:text-h4 text-gray-900 focus:bg-creme-100 focus:outline-none appearance-none focus:ring-transparent;
}
</style>
