<template>
  <div class="w-full relative text-gray-900">
    <div
      class="rounded-8 flex flex-row border border-gray-500 lg:p-[26px] px-4 py-3 cursor-pointer"
      :class="[isOpen ? 'bg-creme-50' : 'bg-creme-200']"
      @click="toggleModal"
    >
      <div class="flex flex-row w-full items-center justify-between">
        <div
          class="text-body-2 xl:text-body-1 text-gray-500"
          v-if="selections.length === 0"
        >
          {{ placeholder }}
        </div>
        <div class="text-body-2 xl:text-body-1" v-else>{{ getSelections }}</div>
        <img
          class="transition-transform"
          src="@/assets/ico_arrow_down.svg"
          :class="{ 'rotate-180': isOpen }"
        />
      </div>
    </div>

    <div
      class="open absolute"
      :class="[
        isOpen ? 'rounded-b-8 border border-gray-500 w-full h-auto' : 'h-0',
      ]"
    >
      <div class="open-wrapper">
        <div class="ml-6 my-4 w-full">
          <div
            v-for="choice in choices"
            :key="choice.name"
            class="flex flex-row py-2"
          >
            <Checkbox
              ref="chk-item"
              :id="choice.name"
              :setChecked="setChecked(choice)"
              @input="checkboxInput(choice)"
            />
            <div class="text-body-2 xl:text-body-1 ml-4 flex items-center">
              {{ choice.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from "@/components/inputs/Checkbox.vue";

export default {
  name: "Dropdown",
  components: {
    Checkbox,
  },
  props: {
    choices: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: "Choose",
    },
    oneChoiceAllowed: {
      type: Boolean,
      default: false,
    },
    preselectedChoice: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isOpen: false,
      selections: [],
      visibleSelections: [],
      listener: null,
    };
  },
  methods: {
    toggleModal() {
      this.isOpen = !this.isOpen;
    },
    resetSelections(pressedChkboxName) {
      this.selections = [];
      this.visibleSelections = [];

      this.$refs["chk-item"].forEach((r) => {
        // Reset all checkboxes except the one we clicked on now
        if (!r.$el.id.includes(pressedChkboxName)) {
          r.$data.checked = false;
        }
      });
    },
    checkboxInput(choice) {
      // If selection already exists, just untick it
      if (this.selections.indexOf(choice.value) > -1) {
        this.selections.splice(this.selections.indexOf(choice.value), 1);
        this.visibleSelections.splice(
          this.visibleSelections.indexOf(choice.name),
          1
        );
      } else {
        // If only one choice is allowed, first reset all chkboxes except one we clicked now
        if (this.oneChoiceAllowed && this.selections.length) {
          this.resetSelections(choice.name);
        }

        this.selections.push(choice.value);
        this.visibleSelections.push(choice.name);

        if (this.oneChoiceAllowed) {
          this.toggleModal();
        }
      }

      this.$emit("input", this.selections.join(";"));
    },
    setChecked(choice) {
      return (
        this.preselectedChoice !== "" &&
        choice.name.includes(this.preselectedChoice)
      );
    },
  },
  computed: {
    getSelections() {
      if (this.visibleSelections.length <= 3) {
        return this.visibleSelections.join(", ");
      } else {
        return `${this.visibleSelections.length} locations selected`;
      }
    },
  },
  created() {
    this.listener = window.addEventListener("click", (e) => {
      if (!this.$el.contains(e.target)) {
        if (this.isOpen) {
          this.toggleModal();
        }
      }
    });
  },
  mounted() {
    if (this.preselectedChoice !== "" && this.choices.length) {
      const foundChoice = this.choices.find((c) =>
        c.name.includes(this.preselectedChoice)
      );

      if (foundChoice) {
        this.checkboxInput(foundChoice);
      }
    }
  },
  beforeDestroy() {
    if (this.listener) {
      window.removeEventListener(this.listener);
    }
  },
};
</script>
<style scoped>
.open {
  z-index: 100;
  @apply flex flex-col -mt-1 overflow-y-scroll overflow-x-hidden bg-creme-200;
}
.open-wrapper {
  @apply flex flex-col xl:flex-row;
}
</style>
