import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

import { Auth } from "aws-amplify";

Vue.use(VueRouter);

/* eslint-disable no-unused-vars */
const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: false,
      title: "HomeFounder",
    },
  },

  {
    path: "/faq/:questionId",
    name: "FaqID",
    redirect: (to) => {
      console.log("kinda working", to.params.questionId);
      return {
        name: "Faq",
        params: { id: to.params.questionId * 1 },
      };
    },
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/faq",
    name: "Faq",
    component: () => import(/* webpackChunkName: "faq" */ "../views/Faq.vue"),
    meta: {
      requiresAuth: false,
      title: "Frequently Asked Questions",
    },
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
    meta: {
      requiresAuth: false,
      title: "About us",
    },
  },
  {
    path: "/builder",
    name: "HomeBuilder",
    component: () =>
      import(/* webpackChunkName: "builder" */ "../views/HomeBuilder.vue"),
    meta: {
      requiresAuth: true,
      app: true,
      title: "Builder",
    },
  },
  {
    path: "/locations/:slug",
    name: "Locations",
    component: () =>
      import(/* webpackChunkName: "Locations" */ "../views/Locations.vue"),
    meta: {
      requiresAuth: false,
      title: "Locations",
    },
  },
  {
    path: "/locations/:slug/:houseslug",
    name: "HouseListing",
    component: () =>
      import(
        /* webpackChunkName: "house-listing" */ "../views/HouseListing.vue"
      ),
    meta: {
      requiresAuth: false,
      title: "House",
    },
  },
  {
    name: "LocationInterest",
    path: "/locations",
    component: () =>
      import(
        /* webpackChunkName: "locations" */ "../views/LocationInterest.vue"
      ),
    meta: {
      title: "Looking for another location?",
    },
  },
  {
    path: "/thanks-locations",
    name: "ThankYouLocations",
    component: () =>
      import(
        /* webpackChunkName: "thanks-locations" */ "../views/ThankYouLocations.vue"
      ),
    meta: {
      requiresAuth: false,
      title: "Thank you!",
    },
  },
  {
    path: "/thanks-contact",
    name: "ThankYouContact",
    component: () =>
      import(
        /* webpackChunkName: "thanks-contact" */ "../views/ThankYouContact.vue"
      ),
    meta: {
      requiresAuth: false,
      title: "Thank you!",
    },
  },
  {
    path: "/thanks-holly",
    name: "ThankYouHolly",
    component: () =>
      import(
        /* webpackChunkName: "thanks-holly" */ "../views/ThankYouHolly.vue"
      ),
    meta: {
      requiresAuth: false,
      title: "Thank you!",
    },
  },
  {
    path: "/contact",
    name: "Contact",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/Contact.vue"),
    meta: {
      requiresAuth: false,
      title: "Contact us",
    },
  },
  {
    path: "/how-it-works",
    name: "HowItWorks",
    component: () =>
      import(/* webpackChunkName: "how-it-works" */ "../views/HowItWorks.vue"),
    meta: {
      requiresAuth: false,
      title: "How it works",
    },
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: () =>
      import(/* webpackChunkName: "privacy" */ "../views/Privacy.vue"),
    meta: {
      requiresAuth: false,
      title: "Privacy",
    },
  },
  {
    path: "/terms",
    name: "Terms",
    component: () =>
      import(/* webpackChunkName: "terms" */ "../views/Terms.vue"),
    meta: {
      requiresAuth: false,
      title: "Terms of Use",
    },
  },
  {
    path: "/cookies",
    name: "Cookie Policy",
    component: () =>
      import(/* webpackChunkName: "cookies" */ "../views/Cookies.vue"),
    meta: {
      requiresAuth: false,
      title: "Cookie Policy",
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"),
    meta: {
      requiresAuth: true,
      app: true,
      title: "Dashboard",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // if (savedPosition) {
    //   return savedPosition;
    // }
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    }
    document.getElementById("app").scrollTop = 0;
    return { x: 0, y: 0 };
  },
});

router.beforeResolve((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        next();
      })
      .catch((err) => {
        next({
          path: "/",
        });
      });
  } else {
    next();
  }
});

router.afterEach((to, from) => {
  if (to.matched.some((record) => record.meta.app)) {
    Vue.prototype.$navActions.setNavigationToMarketing(false);
  } else {
    Vue.prototype.$navActions.setNavigationToMarketing(true);
  }

  if (to.name == "Dashboard") {
    Vue.prototype.$navActions.setDashboardSelected();
  } else if (to.name == "HomeBuilder") {
    Vue.prototype.$navActions.setMyHomeSelected();
  } else {
    Vue.prototype.$navActions.setNoneSelected();
  }
  Vue.nextTick(() => {
    document.title = to.meta.title || "HomeFounder";
  });
});

export default router;
