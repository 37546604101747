/**
 * Takes a plain number or string of the amount, example: 120000
 * @param {string | number} amount
 * @returns a formated string with currency and compact notation, example: $120k
 */
export function roundNumberToShorthandCurrency(amount) {
  if (amount || amount === 0)
    return Intl.NumberFormat("en-US", {
      notation: "compact",
      compactDisplay: "short",
      style: "currency",
      currency: "USD",
      currencyDisplay: "narrowSymbol",
    }).format(parseInt(amount));
  return 0;
}

/**
 * Takes a plain number or string of the amount, example: 120000.50
 * @param {number | string} amount
 * @returns a formated string with currency, US formatting and without decimals, example: $120,000
 */
export function currencyNoDecimal(amount) {
  if (amount || amount === 0)
    return Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      currencyDisplay: "narrowSymbol",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(parseInt(amount));
  return 0;
}
