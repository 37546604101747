export const HUBSPOT_PAGES = {
  HOLLYSPRINGS: "hollysprings",
  LOCATIONS: "locations",
  CONTACT: "contact",
};

export const HUBSPOT_DATA = {
  OBJECT_TYPE_ID: "0-1",
  COOKIE_NAME: "hubspotutk",
  FIRST_NAME: "firstname",
  LAST_NAME: "lastname",
  EMAIL: "email",
  IDEAL_MOVE_DATE: "ideal_move_date",
  IS_AGENT: "is_agent",
  HOME_MODEL_INTEREST: "holly_springs_home_choice",
  IS_FTHB: "is_fthb",
  LEAD_FORM_COMMENTS: "lead_form_comments",
  LOCATIONS_INTERESTED: "locations_interested",
};

export const MOVE_IN_DATES_ARRAY = [
  { name: "In 0-3 months", value: "0_3mo" },
  { name: "In 4-6 months", value: "4_6mo" },
  { name: "In 6-9 months", value: "7_9mo" },
  { name: "In 10-12 months", value: "10_12mo" },
  { name: "In 13-18 months", value: "13-18mo" },
  { name: "In 1.5+ years", value: "18plusmo" },
  {
    name: "Anytime, for the right opportunity",
    value: "anytime",
  },
];

export const HUBSPOT_API_URL = {
  HOLLYSPRINGS:
    "https://api.hsforms.com/submissions/v3/integration/submit/21550466/29a18ceb-1c22-45fa-b0bd-9f01976ef7b2",
  LOCATIONS:
    "https://api.hsforms.com/submissions/v3/integration/submit/21550466/b6a747af-c8a5-4fe6-9164-937fbadbafa6",
  CONTACT:
    "https://api.hsforms.com/submissions/v3/integration/submit/21550466/76c8940b-1fef-40f6-a9b9-77282973678d",
};
