<template>
  <div class="max-container">
    <div class="main-grid text-center">
      <div class="col-span-full xl:col-span-6 xl:col-start-4">
        <h2 class="text-h3 sm:text-h2 font-semibold max-w-3xl mx-auto mb-6">
          Welcome to a <span class="text-blue-500">new era</span>
          <br class="hidden xl:block" />
          in homebuying.
        </h2>
        <p
          class="hidden xl:block text-body-1 sm:text-h4 max-w-2xl mb-12 mx-auto"
        >
          And soon, welcome home.
        </p>
        <p class="xl:hidden text-body-2 sm:text-h4 max-w-2xl mb-12 mx-auto">
          Browse our upcoming communities or get notified for our future
          locations.
        </p>
        <div class="flex flex-col items-center md:justify-center mb-4">
          <PrimaryBaseCta
            class="mb-8"
            text="Contact us to learn more"
            endImage="ico_circle_arrow_white.svg"
            @click="onClick"
          />
          <a
            href="/locations/hollysprings"
            class="text-body-2 sm:text-h4 text-blue-500 font-semibold"
          >
            Explore our newest community
          </a>
        </div>
      </div>
      <div class="col-span-full">
        <img
          class="mx-auto mb-[-5%] billboard-image"
          src="@/assets/images/home_page_welcome_billboard_HQ.png"
          alt="Welcome billboard"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { PrimaryBaseCta } from "@/components";
export default {
  name: "FounderPage",
  components: {
    PrimaryBaseCta,
  },
  methods: {
    onClick() {
      this.$router.push({
        path: "/contact",
      });
    },
  },
};
</script>
<style scoped>
.background-gradient {
  background: linear-gradient(180deg, #fcfbf8 0%, #f5f0e9 100%);
}
.billboard-image {
  max-width: 800px;
  width: 100%;
}
</style>
